<template>
  <v-container>
    <v-card class="pa-0">
      <v-card-title>
        Vendor Object List
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          class="pa-0"
        ></v-text-field>
      </v-card-title>
      <v-divider></v-divider>
      <v-data-table
        dense
        :headers="headers"
        :search="search"
        :items="items.data"
        :server-items-length="items.total"
        :options.sync="pagination"
        :loading="loading"
        item-key="name"
        class="elevation-1"
      >
        <template v-slot:item.file_url="{item}">
          <v-btn
            color="grey darken-2 px-2"
            small
            dark
            @click="showPreview(item)"
          >
            <v-icon class="pr-2">mdi-image-area</v-icon>View
          </v-btn>
        </template>
        <template v-slot:item.link="{item}">
          <v-btn color="grey darken-2" small dark :href="item.link" target="_blank" v-if="item.link">
            Open link
          </v-btn>
          <span v-else class="align-center items-center"> - </span>
        </template>
        <template v-slot:item.action="{item}">
          <v-btn color="grey darken-2" icon dark :to="'/vendor/edit_object/'+item.object_id">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <v-btn color="primary" fixed fab bottom right :to="'/vendor/add_object/'+vendor_id">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <!-- Delete confirmation -->
    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-text class="pt-5">
          Are you sure delete
          <b>{{vendor.name}}</b>?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" text @click="dialog = false">Cancel</v-btn>
          <v-btn color="red darken-4" text @click="deleteItem(vendor)">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Video play -->
    <v-dialog v-model="show_preview" max-width="500" v-if="show_preview">
      <v-card>
        <v-card-text class="pt-2 rounded-lg px-0">
          <iframe :src="preview_data" frameborder="0" width="100%" style="max-height:500px"></iframe>
        </v-card-text>
         <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" text @click="show_preview = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-btn small color="secondary" fixed fab bottom left to="/vendor">
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>
  </v-container>
</template>
<script>

import { parseParams, getAlphabets } from "../../plugins/helper";
export default {
  name: "Vendorlist",
  data() {
    return {
      loading: false,
      dialog: false,
      preview_data:null,
      show_preview:false,
      vendor_id:null,
      vendor: {
        name: null,
      },
      search: "",
      items: {
        current_page: 1,
        per_page: 10,
        data: [],
      },
      headers: [
        { text: "Content Postion", value: "position_name" },
        { text: "Image/Video", value: "file_url", sortable: false },
        { text: "Link", value: "link",sortable: false ,align: 'center'},
        { text: "Action", value: "action", sortable: false },
      ],
      pagination: {
        itemsPerPage: 10,
        sortBy: ["created_at"],
        page: 1,
        filters: {
          item_active: true,
          alphabet: "",
        },
      },
      alphabets: getAlphabets(),
    };
  },
  computed: {
    pageData() {
      let paginationData = this.pagination;
      paginationData.search = this.search;
      paginationData.searchOption = "name";
      return paginationData;
    },
    getStyles() {
      if (this.$vuetify.breakpoint.xsOnly) {
        return {
          maxWidth: "95%",
          margin: "0 auto",
        };
      } else {
        return {};
      }
    },
  },
  watch: {
    search() {
      this.getItems();
    },
    pagination: {
      handler: function () {
        this.getItems();
      },
      deep: true,
    },
  },
  methods: {
    getFormatedDate(date) {
      return new Date(date).toLocaleDateString();
    },
    getItems() {
      let _self = this;
      this.loading = "secondary";
      let url = parseParams(this.pageData);
      this.$axios.get("/admin/vendor/object/list?" + url+'&vendor_id='+_self.vendor_id)
        .then((response) => {
          _self.items = response.data.data;
          this.loading = false;
        })
        .catch(function () {
          this.loading = false;
        });
    },
    showPreview(item) {
      this.preview_data = this.FRONT_ASSETS +'../uploads/objects/'+item.path;
      this.show_preview = true;
    },
    confirmDelete(item) {
      this.dialog = true;
      this.vendor = item;
    },
    deleteItem(item) {
      const index = this.items.data.indexOf(item);
      let _self = this;
      _self.loading = "secondary";
      this.$axios.post("/admin/vendor/delete", {
        vendor_id: item.vendor_id,
      })
        .then((res) => {
          if (res.status) {
            _self.items.data.splice(index, 1);
            _self.dialog = false;
          }
          _self.loading = false;
        })
        .catch(function () {
          _self.loading = false;
        });
    },
  },
  mounted: function () {
    this.vendor_id = this.$route.params.vendor_id
  },
};
</script>